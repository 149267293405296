import React from 'react'
import { Item, Header } from 'semantic-ui-react'
import Img from 'gatsby-image'
import { withTranslation, Trans } from 'react-i18next'

import { getFluidImage } from '../../utils/images'

const EmployeeCard = ({
  color,
  employee: { name, phone, mail, gender, address, images },
  t
}) => {
  return (
    <Item.Group>
      <Item
        className='inverted centered'
        style={{ backgroundColor: color, height: 150 }}
      >
        {images && !!images.length && (
          <Item.Image size='small'>
            <Img
              fluid={getFluidImage({
                cloudinary: images[0].cloudinary,
                ar: 1,
                maxWidth: 300
              })}
            />
          </Item.Image>
        )}
        <Item.Content verticalAlign='middle'>
          <Item.Header style={{ marginTop: images ? 0 : 15 }}>
            <Header as='h4' inverted>
              <Trans i18nKey={`dossier.employee.ask.${gender}`}>
                Haben Sie Fragen?
                <br />
                Bitte kontaktieren Sie Ihren persönlichen Reise-Spezialisten!
              </Trans>
            </Header>
            <Header as='h4' inverted>
              {name}
              <br />
              {address && (
                <span className='light'>
                  {address}
                  <br />
                </span>
              )}
              <span className='light'>
                {t('dossier.employee.phone')}: {phone}, {mail}
              </span>
            </Header>
          </Item.Header>
        </Item.Content>
      </Item>
    </Item.Group>
  )
}

export default withTranslation()(EmployeeCard)
