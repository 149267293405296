import React from 'react'
import { withTranslation } from 'react-i18next'

import ImageLightbox from '../Items/ImageLightbox'
import HotelDescription from '../Items/HotelDescription'
import ImageSectionSlider from '../Items/ImageSectionSlider'

const HotelDescriptions = ({ lightboxId, images, description: { facility, catering, leisure, opinion, location, intro }, t }) => {
  const allImages = [...(images.intro || []), ...(images.room || []), ...(images.food || []), ...(images.entertainment || []), ...(images.location || [])]

  return (
    <div>
      <p><strong dangerouslySetInnerHTML={{ __html: (intro && intro.description) || '' }} /></p>
      {images.intro && <ImageSectionSlider images={images.intro} lightboxId={lightboxId} />}
      <HotelDescription lightboxId={lightboxId} section={facility} images={images.room} inline />
      <HotelDescription lightboxId={lightboxId} section={catering} images={images.food} inline />
      <HotelDescription lightboxId={lightboxId} section={leisure} images={images.entertainment} inline />
      <HotelDescription lightboxId={lightboxId} section={location} images={images.location} inline />
      <HotelDescription lightboxId={lightboxId} section={opinion} images={images.opinion} inline />
      {images && <ImageLightbox id={lightboxId} images={allImages} />}
    </div>
  )
}

export default withTranslation()(HotelDescriptions)
