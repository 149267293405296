import React, { Component } from 'react'
import {
  Image,
  Segment,
  List,
  Container,
  Breadcrumb,
  Header,
  Icon,
  Menu
} from 'semantic-ui-react'
import { withTranslation, Trans } from 'react-i18next'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'

import { getFluidImage, getRawImage } from '../utils/images'
import { getSearchParams, scrollToTop } from '../utils/helpers'
import { colorRed } from '../utils/constants'
import { fetchDossier } from '../utils/dossier'
import createPdf from '../utils/dossier-pdf'

import Program from '../components/Dossier/Program'
import ProgramMobile from '../components/Dossier/ProgramMobile'
import Prices from '../components/Dossier/Prices'
import PricesMobile from '../components/Dossier/PricesMobile'
import ScrollToTopButton from '../components/Common/ScrollToTopButton'

import logoDe from '../assets/Logo_de.svg'
import logoFr from '../assets/Logo_fr.svg'

class Dossier extends Component {
  state = {
    menu: 'program',
    sections: [],
    general: {},
    prices: {},
    employee: {},
    agency: { customColor: colorRed },
    infoPages: [],
    loading: false,
    error: false,
    headerImage: {}
  }

  handleContextRef = contextRef => this.setState({ contextRef })

  componentDidMount = () => {
    const params = getSearchParams(window.location.search)
    const dossierId = params['id']

    this.setState({ loading: true }, () =>
      fetchDossier(dossierId)
        .then(info => {
          const customColor = info.agency.customColor || this.state.customColor
          document.styleSheets[0].insertRule(
            `::selection { background: ${customColor} !important; }`,
            0
          )
          return this.setState({ ...info, loading: false })
        })
        .catch(reason => {
          console.log(reason)
          this.setState({ loading: false, error: true })
        })
    )

    scrollToTop()
  }

  generatePdf = ({ print = false }) => {
    const {
      sections,
      general,
      additionalReference1,
      prices,
      employee,
      agency,
      infoPages
    } = this.state
    const language = this.props.i18n.language

    if (typeof window !== 'undefined') {
      return createPdf(
        { sections, general, prices, employee, agency, infoPages, additionalReference1 },
        { print, language }
      )
    }
  }

  render() {
    const {
      agency: { customLogo, customColor },
      general,
      additionalReference1,
      sections,
      employee,
      prices,
      menu,
      loading,
      infoPages,
      error,
      headerImage
    } = this.state
    const { t, i18n } = this.props

    if (error) {
      return (
        <Segment basic vertical className='lightgrey' inverted>
          <Container textAlign='center'>
            <Header as='h1' textAlign='center'>
              {t('dossier.error')}
            </Header>
          </Container>
        </Segment>
      )
    }

    return (
      <Segment
        basic
        vertical
        className='lightgrey dossier'
        inverted
        style={{ paddingTop: 0, '::selection': { background: customColor } }}
        loading={loading}
      >
        <Helmet>
          <html lang={i18n.language} />
          <title>
            {t('dossier.general.offer')} {general.dossierNumber || ''}
          </title>
        </Helmet>

        <div className='max-tablet'>
          {customLogo ? (
            <Image
              src={getRawImage({ cloudinary: customLogo, width: 300 })}
              size='medium'
              centered
            />
          ) : (
            <Image
              src={i18n.language === 'de' ? logoDe : logoFr}
              style={{ height: '91px' }}
              size='medium'
              centered
              alt='lets go tours logo'
            />
          )}
          {headerImage.cloudinary ? (
            <Img
              fluid={getFluidImage({
                cloudinary: headerImage.cloudinary,
                transformation: headerImage.transformation,
                ar: 2.5,
                alt: headerImage.title
              })}
            />
          ) : (
            <Img
              fluid={getFluidImage({
                cloudinary: 'offer/Baglioni_24_ognypy',
                ar: 3
              })}
            />
          )}

          <Container>
            <Segment basic vertical>
              <Breadcrumb
                size='small'
                style={{ textAlign: 'center', width: '100%' }}
              >
                <Breadcrumb.Section>
                  {t('dossier.general.number')}: N° {general.dossierNumber}
                </Breadcrumb.Section>
                <br />
                <Breadcrumb.Section>
                  {t('dossier.general.dates')}: {general.createDate}
                </Breadcrumb.Section>
              </Breadcrumb>
            </Segment>

            <Segment basic vertical textAlign='center'>
              <Header as='h2'>
                {t('dossier.general.offer')} «{additionalReference1 && additionalReference1.nodeValue ? additionalReference1.nodeValue : general.title}»{' '}
                {t('dossier.prices.from')} {general.firstDate}{' '}
                {t('dossier.prices.to')} {general.lastDate}
                <Header.Subheader>
                  <span style={{ color: customColor }}>
                    {t('dossier.general.offer-for')}{' '}
                    {general.travellers &&
                      general.travellers.map(t => t.name).join(', ')}
                  </span>
                </Header.Subheader>
              </Header>
              <Header as='h4' className='light'>
                {t('dossier.general.intro')}:
              </Header>
              <Icon
                className='ta-arrow-1-down'
                size='large'
                style={{ color: customColor }}
              />
            </Segment>

            <Menu fluid widths={2} secondary pointing size='massive'>
              <Menu.Item
                name='program'
                active={menu === 'program'}
                onClick={() => this.setState({ menu: 'program' })}
                style={{
                  color: menu === 'program' ? customColor : '',
                  borderBottomColor: menu === 'program' ? customColor : ''
                }}
              >
                {t('dossier.general.program')}
              </Menu.Item>
              <Menu.Item
                name='prices'
                active={menu === 'prices'}
                onClick={() => this.setState({ menu: 'prices' })}
                style={{
                  color: menu === 'prices' ? customColor : '',
                  borderBottomColor: menu === 'prices' ? customColor : ''
                }}
              >
                {prices.visibility && prices.visibility.priceBox && !!prices.items && prices.items.length > 0
                  ? t('dossier.general.prices')
                  : t('dossier.general.prices-noPriceBox')}
              </Menu.Item>
            </Menu>

            {menu === 'program' && (
              <ProgramMobile color={customColor} sections={sections} />
            )}
            {menu === 'prices' && (
              <PricesMobile
                color={customColor}
                prices={prices}
                infoPages={infoPages}
                onPdf={this.generatePdf}
              />
            )}
          </Container>

          <List
            inverted
            verticalAlign='middle'
            size='small'
            style={{
              backgroundColor: customColor,
              color: '#fff',
              textAlign: 'center'
            }}
          >
            <List.Item>
              {employee.images && !!employee.images.length && (
                <Image size='small'>
                  <Img
                    fluid={getFluidImage({
                      cloudinary: employee.images[0].cloudinary,
                      ar: 1,
                      maxWidth: 300
                    })}
                  />
                </Image>
              )}
              <List.Content
                style={{
                  paddingTop: employee.images ? 0 : 10,
                  paddingBottom: employee.images ? 0 : 10
                }}
              >
                <List.Header>
                  <Trans i18nKey={`dossier.employee.ask.${employee.gender}`}>
                    Haben Sie Fragen?
                    <br />
                    Bitte kontaktieren Sie Ihren persönlichen
                    Reise-Spezialisten!
                  </Trans>
                </List.Header>
                {employee.name}
                <br />
                <span className='light'>
                  {t('dossier.employee.phone')}: {employee.phone},{' '}
                  {employee.mail}
                </span>
              </List.Content>
            </List.Item>
          </List>
        </div>

        <div className='min-tablet'>
          {/* Needs to be this way to position image logo on top absolute within container */}
          <div style={{ position: 'relative', width: '100%' }}>
            {headerImage.cloudinary ? (
              <Img
                fluid={getFluidImage({
                  cloudinary: headerImage.cloudinary,
                  transformation: headerImage.transformation,
                  ar: 2.5,
                  alt: headerImage.title
                })}
              />
            ) : (
              <Img
                fluid={getFluidImage({
                  cloudinary: 'offer/Baglioni_24_ognypy',
                  ar: 3
                })}
              />
            )}
            <Container>
              <div
                style={{
                  position: 'absolute',
                  width: 'inherit',
                  margin: 'inherit',
                  top: 0
                }}
              >
                {customLogo ? (
                  <Image
                    src={getRawImage({ cloudinary: customLogo, width: 300 })}
                  />
                ) : (
                  <Image
                    src={i18n.language === 'de' ? logoDe : logoFr}
                    style={{ height: '91px' }}
                    size='medium'
                  />
                )}
              </div>
            </Container>
          </div>

          <Container>
            <Segment basic vertical>
              <Breadcrumb size='small'>
                <Breadcrumb.Section>
                  {t('dossier.general.number')}: N° {general.dossierNumber}
                </Breadcrumb.Section>
                <Breadcrumb.Divider> | </Breadcrumb.Divider>
                <Breadcrumb.Section>
                  {t('dossier.general.dates')}: {general.createDate}
                </Breadcrumb.Section>
              </Breadcrumb>
            </Segment>
            <Segment basic vertical textAlign='center'>
              <Header as='h2'>
                {t('dossier.general.offer')} «{additionalReference1 && additionalReference1.nodeValue ? additionalReference1.nodeValue : general.title}»{' '}
                {t('dossier.prices.from')} {general.firstDate}{' '}
                {t('dossier.prices.to')} {general.lastDate}
                <Header.Subheader>
                  <span style={{ color: customColor }}>
                    {t('dossier.general.offer-for')}{' '}
                    {general.travellers &&
                      general.travellers.map(t => t.name).join(', ')}
                  </span>
                </Header.Subheader>
              </Header>
              <Header as='h4' className='light'>
                {t('dossier.general.intro')}:
              </Header>
              <Icon
                className='ta-arrow-1-down'
                size='large'
                style={{ color: customColor }}
              />
            </Segment>

            <Menu fluid widths={2} secondary pointing size='massive'>
              <Menu.Item
                name='program'
                active={menu === 'program'}
                onClick={() => this.setState({ menu: 'program' })}
                style={{
                  color: menu === 'program' ? customColor : '',
                  borderBottomColor: menu === 'program' ? customColor : ''
                }}
              >
                {t('dossier.general.program')}
              </Menu.Item>
              <Menu.Item
                name='prices'
                active={menu === 'prices'}
                onClick={() => this.setState({ menu: 'prices' })}
                style={{
                  color: menu === 'prices' ? customColor : '',
                  borderBottomColor: menu === 'prices' ? customColor : ''
                }}
              >
                {prices.visibility && prices.visibility.priceBox && !!prices.items && prices.items.length > 0
                  ? t('dossier.general.prices')
                  : t('dossier.general.prices-noPriceBox')}
              </Menu.Item>
            </Menu>

            {menu === 'program' && (
              <Program
                color={customColor}
                sections={sections}
                employee={employee}
              />
            )}
            {menu === 'prices' && (
              <Prices
                color={customColor}
                prices={prices}
                general={general}
                additionalReference1={additionalReference1}
                employee={employee}
                infoPages={infoPages}
                onPdf={this.generatePdf}
              />
            )}
          </Container>
        </div>

        <ScrollToTopButton />
      </Segment>
    )
  }
}

export default withTranslation()(Dossier)
