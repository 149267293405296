import React, { Component } from 'react'
import Slider from 'react-slick'
import { Grid, Segment, Header, Icon, Divider } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

import StarCounter from '../Common/StarCounter'
import TourProgram from './TourProgram'
import HotelProgram from './HotelProgram'
import FlightProgram from './FlightProgram'
import TransportProgram from './TransportProgram'
import TripProgram from './TripProgram'
import { colorGraySecond } from '../../utils/constants'

class ProgramMobile extends Component {
  state = {
    activeCard: 0
  }

  render () {
    const { activeCard } = this.state
    const { t, sections, color } = this.props

    const settingsNav = {
      infinite: true,
      arrows: false,
      dots: false,
      slidesToShow: Math.min((sections || []).length + 1, 3),
      slidesToScroll: 1,
      swipeToSlide: true,
      afterChange: current => this.setState({ activeCard: current }, () => this.sliderCard.slickGoTo(current))
    }

    const settingsCards = {
      infinite: false,
      arrows: false,
      dots: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: true,
      afterChange: current => this.setState({ activeCard: current }, () => this.sliderNav.slickGoTo(current))
    }

    return (
      <div>
        <Grid>
          <Grid.Row centered>
            <Grid.Column width={2} verticalAlign='middle'>
              <Icon className='ta-arrow-1-left' size='large' onClick={() => this.sliderNav.slickPrev()} style={{ float: 'left', cursor: 'pointer', color }} />
            </Grid.Column>
            <Grid.Column width={12} textAlign='center'>
              <Slider ref={c => (this.sliderNav = c)} {...settingsNav}>
                <span>
                  <Icon className='ta-menu' style={{ color: '#fff', backgroundColor: activeCard === 0 ? color : colorGraySecond }} size='large' circular onClick={() => this.sliderNav.slickGoTo(0)} />
                </span>
                {sections.map(({ id, icon }, idx) => (
                  <span key={id}>
                    <Icon
                      className={icon}
                      style={{ color: '#fff', backgroundColor: activeCard === idx + 1 ? color : colorGraySecond }}
                      size='large'
                      circular
                      onClick={() => this.sliderNav.slickGoTo(idx + 1)}
                    />
                  </span>
                ))}
              </Slider>
            </Grid.Column>
            <Grid.Column width={2} verticalAlign='middle'>
              <Icon className='ta-arrow-1-right' size='large' onClick={() => this.sliderNav.slickNext()} style={{ cursor: 'pointer', float: 'right', color }} />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider hidden clearing />

        <Slider ref={slider => (this.sliderCard = slider)} {...settingsCards}>
          <div>
            <Segment inverted attached='top' style={{ backgroundColor: color }}>
              <Header as='h3' inverted>{t('dossier.program.overview')}</Header>
            </Segment>
            <Segment attached>
              {sections.map(({ id, section, icon, beginDate, endDate }, idx) => (
                <Header as='h4' dividing key={id} style={{ color }} onClick={() => this.sliderNav.slickGoTo(idx + 1)}>
                  <Icon className={icon} style={{ color }} />
                  <Header.Content>
                    <Header.Subheader>{beginDate} {endDate ? `${t('dossier.program.to')} ${endDate}` : ''}</Header.Subheader>
                    {section}
                  </Header.Content>
                </Header>
              ))}
            </Segment>
          </div>
          {sections.map(item => (
            <Segment key={item.id} basic padded className='white'>
              <Header as='h3' style={{ color }}>
                <Icon className={`${item.icon} only-computer`} circular style={{ color: '#fff', backgroundColor: color }} />
                <Header.Content>
                  {item.title} <StarCounter count={item.rating} color={color} />
                  <Header.Subheader>{t('dossier.travellers')}: {item.travellers && item.travellers.join(', ')}</Header.Subheader>
                </Header.Content>
              </Header>
              <Divider />
              {item.type === 'airlines' &&
                <FlightProgram color={color} flight={item} />
              }
              {item.type === 'transports' &&
                <TransportProgram color={color} transport={item} />
              }
              {item.type === 'hotels' &&
                <HotelProgram color={color} hotel={item} />
              }
              {item.type === 'tours' &&
                <TourProgram color={color} tour={item} />
              }
              {item.type === 'trips' &&
                <TripProgram color={color} transport={item} />
              }
            </Segment>
          ))}
        </Slider>
      </div>
    )
  }
}

export default withTranslation()(ProgramMobile)
