export const getFluidImage = ({
  cloudinary,
  options,
  maxWidth = 1920,
  ar = 1.5,
  transformation = ''
}) => {
  const widths = [320, 768, 992, 1200, 1920]

  const defaultId = 'exterior-taj-samudra-facade-68846'
  const defaultOptions = `f_auto,q_auto:good,c_crop`
  const url = 'https://res.cloudinary.com/lets-go-tours/image/upload'

  const calcOptions = `${defaultOptions}${options ? ',' + options : ''}`

  const src = `${url}/${
    transformation ? transformation + '/' : ''
  }${calcOptions}/w_${maxWidth}/${cloudinary || defaultId}.jpg`
  const srcSet = widths
    .map(
      width =>
        `${url}/${
          transformation ? transformation + '/' : ''
        }${calcOptions}/w_${width}/${cloudinary || defaultId}.jpg ${width}w`
    )
    .join(',')

  const sizes = `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`

  return {
    aspectRatio: ar,
    sizes,
    src,
    srcSet
  }
}

export const getFooterImage = ({ cloudinary }) => {
  const widths = [320, 768, 992, 1200, 1920]
  const maxWidth = 768

  const url = 'https://res.cloudinary.com/lets-go-tours/image/upload'
  const options = 'h_75,q_90,ar_1.5,c_pad'

  const src = `${url}/${options}/${cloudinary}.jpg`
  const srcSet = widths
    .map(width => `${url}/${options}/${cloudinary}.jpg ${width}w`)
    .join(',')

  const sizes = `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`

  return {
    width: 112,
    height: 75,
    sizes,
    src,
    srcSet
  }
}

export const getRawImage = ({ cloudinary, width = 768, format = 'jpg' }) => {
  if (!cloudinary) {
    return ''
  }
  return `https://res.cloudinary.com/lets-go-tours/image/upload/w_${width}/${cloudinary}.${format}`
}
