import React, { Component } from 'react'
import { Grid, Segment, Visibility, Sticky, Header, List, Icon, Divider } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

import TourProgram from './TourProgram'
import HotelProgram from './HotelProgram'
import FlightProgram from './FlightProgram'
import TransportProgram from './TransportProgram'
import TripProgram from './TripProgram'
import MiscProgram from './MiscProgram'
import EmployeeCard from './EmployeeCard'
import StarCounter from '../Common/StarCounter'
import { colorGraySecond } from '../../utils/constants'

const defaultEmployee = {
  name: "Let's go Tours AG",
  gender: "a",
  phone: "052 624 10 77",
  mail: "tours@letsgo.ch",
  address: "Vorstadt 33, 8200 Schaffhausen"
}

class Program extends Component {
  state = {
    sectionsVisibility: []
  }

  handleContextRef = contextRef => this.setState({ contextRef })

  handleUpdate = (id, calculations) => {
    const { topVisible, fits, passing } = calculations
    const { sectionsVisibility } = this.state

    const newSections = [...sectionsVisibility]

    if (fits) {
      newSections[id] = 'fits'
    } else if (topVisible) {
      newSections[id] = 'topVisible'
    } else if (passing) {
      newSections[id] = 'passing'
    } else {
      newSections[id] = false
    }

    if (newSections[id] !== sectionsVisibility[id]) {
      this.setState({ sectionsVisibility: newSections })
    }
  }

  render() {
    const { t, sections, employee, color } = this.props
    const { contextRef, sectionsVisibility } = this.state
    let activeSection = 0

    if (sectionsVisibility.some(section => section === 'fits')) {
      activeSection = sectionsVisibility.indexOf('fits')
    } else if (sectionsVisibility.some(section => section === 'topVisible')) {
      activeSection = sectionsVisibility.indexOf('topVisible')
    } else if (sectionsVisibility.some(section => section === 'passing')) {
      activeSection = sectionsVisibility.indexOf('passing')
    }

    return (
      <div ref={this.handleContextRef}>
        <Segment basic vertical>
          <Grid>
            <Grid.Column width={5}>
              <Sticky context={contextRef} offset={20} className='offer-side-bar'>
                <Segment inverted attached='top' style={{ backgroundColor: color }}>
                  <Header as='h3' inverted>{t('dossier.program.overview')}</Header>
                </Segment>
                <Segment attached>
                  <List divided verticalAlign='middle' relaxed>
                    {sections.map(({ id, section, icon, beginDate, endDate }, idx) => (

                      <List.Item as='a' href={`#${id}`} key={id}>
                        <List.Content floated='right'>
                          <Icon size='large' className={icon} style={{ marginTop: '6px', color: '#fff', backgroundColor: activeSection === idx ? color : colorGraySecond }} circular />
                        </List.Content>
                        <List.Content>
                          <List.Header>
                            <h4 style={{ color: '#777' }}>{beginDate} {endDate ? `${t('dossier.program.to')} ${endDate}` : ''}</h4>
                          </List.Header>
                          <List.Description>
                            <h3 style={{ textTransform: 'capitalize', color }}>{section.toLowerCase()}</h3>
                          </List.Description>
                        </List.Content>
                      </List.Item>

                    ))}
                  </List>
                </Segment>
              </Sticky>
            </Grid.Column>
            <Grid.Column width={11}>
              <EmployeeCard employee={!Object.keys(employee || {}).length ? defaultEmployee : employee} color={color} />

              {sections.map((item, idx) => (
                <Visibility
                  key={item.id}
                  offset={[-10, 10]}
                  onUpdate={(e, { calculations }) => this.handleUpdate(idx, calculations)}
                  id={item.id}
                >
                  <Divider hidden />
                  <Segment basic padded className='white'>
                    <Header as='h3' style={{ color }}>
                      <Icon className={`${item.icon} only-computer`} circular style={{ color: '#fff', backgroundColor: color }} />
                      <Header.Content>
                        {item.title} <StarCounter count={item.rating} color={color} />
                        <Header.Subheader>{t('dossier.travellers')}: {item.travellers && item.travellers.join(', ')}</Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Divider />
                    {item.type === 'airlines' &&
                      <FlightProgram color={color} flight={item} />
                    }
                    {item.type === 'transports' &&
                      <TransportProgram color={color} transport={item} />
                    }
                    {item.type === 'hotels' &&
                      <HotelProgram color={color} hotel={item} />
                    }
                    {item.type === 'tours' &&
                      <TourProgram color={color} tour={item} />
                    }
                    {item.type === 'trips' &&
                      <TripProgram color={color} transport={item} />
                    }
                    {(item.type === 'miscHotels' || item.type === 'miscOther') &&
                      <MiscProgram color={color} misc={item} />
                    }
                  </Segment>
                </Visibility>
              ))}
              {/* extending window height to correctly scroll and highlight sticky elements */}
              <div style={{ height: '50vh' }} />

            </Grid.Column>
          </Grid>
        </Segment>
      </div>
    )
  }
}

export default withTranslation()(Program)
